require('dotenv').config()
import {io, Socket} from 'socket.io-client'

let socket: Socket

const socketApi = process.env.REACT_APP_SOCKET

export const connectSocket = () => {
  if (!socket) {
    socket = io(`${socketApi}`, {
      transports: ['websocket']
    })
  }

  socket.on('connect', async () => {
    console.log('Conectado ao servidor Socket.IO do aplicativo.')
  })

  socket.on('connect_error', (err: any) => {
    console.log(`connect_error due to ${err.message}`)
  })
  return socket
}

export const joinRoom = (roomName: string) => {
  if (socket) {
    socket.emit('joinRoom', roomName)
    console.log('entrou na room: ' + roomName)
  }
}

export const disconnectSocket = (roomName: string) => {
  try {
    socket.emit('leaveRoom', roomName)
    console.log('deixou a room: ' + roomName)
  } catch (error) {
    console.error('Error disconnectingg socket:', error)
  }
}

export const getSocket = () => socket

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, {useState, useEffect, useCallback} from 'react'
import {Chart} from 'react-google-charts'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {useSocketError} from 'hooks/useSocketError'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

export const AppVendaMensal: React.FC = () => {
  const socket = getSocket()
  const classes = useStyles()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const FILIAL_APP = '@FILIAL_APP'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const filiais = localStorage.getItem(FILIAL_APP)
  const [loading, setLoading] = useState(false)
  const [socketConnected, setSocketConnected] = useState(false)
  const [dados, setDados] = useState<any>(null)
  const [chartColors, setChartColors] = useState<string[]>([])
  const [tableColors, setTableColors] = useState<string[]>([])
  const [total, setTotal] = useState<number | null>(null)
  const [ticketMedio, setTicketMedio] = useState<number | null>(null)
  const [margemLucro, setMargemLucro] = useState<number | null>(null)
  const [selectedValue, setSelectedValue] = useState<string>(
    getCurrentMonthYear()
  )
  const filteredData = getLast12Months()

  useSocketError({
    socket,
    errorHandler: () => {
      setLoading(false)
    }
  })

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente

    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = useCallback(
    async (selectedValue: string) => {
      setLoading(true)
      socket.emit('filtroVendaMesApp', {
        message: {
          room: token,
          filial: filiais,
          data: selectedValue
        }
      })

      socket.on('vendaMesDadosApp', (data: any) => {
        try {
          const totalValue = data?.dados?.total
          const ticketMedioValue = data?.dados?.ticketMedio
          const margemLucroValue = data?.dados?.margemLucro
          setDados(data?.dados)
          setTotal(totalValue)
          setTicketMedio(ticketMedioValue)
          setMargemLucro(margemLucroValue)
          const newChartColors = data.dados.formasDePagamento.map(() =>
            getRandomColor()
          )
          setChartColors(newChartColors)
          setTableColors(newChartColors)
        } catch (error) {
          console.error('Erro ao processar dados recebidos:', error)
        } finally {
          setLoading(false)
        }
      })
    },
    [filiais, socket, token]
  )

  const generateReport = useCallback(async () => {
    if (socketConnected) {
      await fetchProducts(selectedValue)
    }
  }, [fetchProducts, selectedValue, socketConnected])

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedValue(event.target.value as string)
  }

  const chartData = [['Descrição', 'Valor', {role: 'style'}]]
  if (dados && dados.formasDePagamento) {
    dados.formasDePagamento.forEach((item: any, index: number) => {
      chartData.push([item.nome, item.valor, chartColors[index]])
    })
  }

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <DrawerApp />
      <Paper className={classes.paper}>
        <InputLabel
          className={classes.label}
          id="demo-controlled-open-select-label"
        >
          Selecione o período
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          value={selectedValue}
          onChange={handleChange}
          className={classes.search}
        >
          {filteredData.map((item) => (
            <MenuItem
              className={classes.menuitem}
              key={item.key}
              value={item.key}
            >
              {item.value}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={generateReport}>Gerar</Button>

        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : !dados || dados.formasDePagamento.length === 0 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          <>
            <Chart
              chartType="PieChart"
              data={chartData}
              options={{
                title: '',
                legend: 'none',
                backgroundColor: 'transparent',
                colors: chartColors
              }}
              width={'100%'}
              height={'300px'}
            />
            <TableContainer className={classes.table} component={Paper}>
              <Table aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell className={classes.rowCell} align="left">
                      Descrição
                    </TableCell>
                    <TableCell className={classes.rowCell} align="left">
                      Valor
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dados.formasDePagamento.map((item: any, index: number) => {
                    const rowStyle = {
                      backgroundColor: tableColors[index]
                    }
                    return (
                      <TableRow key={item.nome}>
                        <TableCell
                          align="left"
                          style={rowStyle}
                          className={classes.legend}
                        ></TableCell>
                        <TableCell className={classes.rowCell} align="left">
                          {item.nome}
                        </TableCell>
                        <TableCell className={classes.rowCell} align="left">
                          {formatPrice(Number(item.valor)) || 'Não informado'}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Card className={classes.card}>
              <CardContent>
                <p className={classes.textLeft}>
                  Total: {total !== null ? formatPrice(total) : 'N/A'}
                </p>
                <p className={classes.textLeft}>
                  Ticket Médio:{' '}
                  {ticketMedio !== null ? formatPrice(ticketMedio) : 'N/A'}
                </p>
                <p className={classes.textLeft}>
                  Margem de Lucro:{' '}
                  {margemLucro !== null ? margemLucro + '%' : 'N/A'}
                </p>
                {total !== null && margemLucro !== null && (
                  <p className={classes.textLeft}>
                    Lucro:{' '}
                    {formatPrice(Number(total) * (Number(margemLucro) / 100))}
                  </p>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </Paper>
    </GridContainer>
  )
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const getLast12Months = () => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]
  const result = []
  const currentDate = new Date()

  for (let i = 0; i < 12; i++) {
    const d = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
    const month = d.getMonth() + 1
    const year = d.getFullYear()
    const monthKey = month < 10 ? `0${month}` : `${month}`
    result.push({
      key: `${year}-${monthKey}`,
      value: `${months[month - 1]} ${year}`
    })
  }

  return result
}

const getCurrentMonthYear = () => {
  const currentDate = new Date()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  return `${year}-${month < 10 ? `0${month}` : month}`
}

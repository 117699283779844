import DateFnsUtils from '@date-io/date-fns'
import {ThemeProvider} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import React from 'react'

import {AutenticacaoProvider} from 'contexts/autenticacao'
import {DialogoProvider} from 'contexts/dialogo'
import {TituloProvider} from 'contexts/titulo'
import {ToastProvider} from 'contexts/toast'

import useTheme from 'hooks/useTheme'

export const Providers: React.FC = ({children}) => {
  /**
   * Hooks
   */
  const {theme} = useTheme()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <AutenticacaoProvider>
          <ToastProvider>
            <TituloProvider>
              <DialogoProvider>
                <CssBaseline />
                {children}
              </DialogoProvider>
            </TituloProvider>
          </ToastProvider>
        </AutenticacaoProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

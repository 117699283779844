import {useEffect} from 'react'
import toast from 'react-hot-toast'
import {Socket} from 'socket.io-client'

export type UseSocketErrorProps = {
  errorHandler: () => void
  socket: Socket
}

export const useSocketError = ({errorHandler, socket}: UseSocketErrorProps) => {
  useEffect(() => {
    socket.on('eventErrorApp', (data: any) => {
      toast.error(data.error, {
        position: 'top-center',
        duration: 10000,
        style: {
          background: 'red',
          color: 'white'
        }
      })

      errorHandler()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])
}

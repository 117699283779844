import secondary from '@material-ui/core/colors/grey'
import {createTheme} from '@material-ui/core/styles'

const useTheme = () => {
  const theme = createTheme({
    typography: {
      fontSize: 16,
      fontFamily: 'Poppins, Roboto, sans-serif'
    },
    palette: {
      primary: {
        main: '#224aea'
      },
      secondary
    },
    props: {
      MuiChip: {
        size: 'small'
      },
      MuiButton: {
        size: 'small',
        variant: 'contained',
        color: 'primary'
      },
      MuiCheckbox: {
        color: 'primary'
      }
    }
  })

  return {theme}
}

export default useTheme

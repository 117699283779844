import {useEffect} from 'react'

import {Loading} from 'components/Loading'

const OutlookRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    if (code) {
      window.opener.postMessage({type: 'outlookAuthCode', code}, '*')

      window.close()
    }
  }, [])

  return <Loading />
}

export default OutlookRedirect

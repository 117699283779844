import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import React, {useState, useEffect, useCallback} from 'react'
import {Toaster} from 'react-hot-toast'
import {Redirect} from 'react-router-dom'
import {getSocket, joinRoom, disconnectSocket, connectSocket} from 'socket'
import {formatPrice} from 'util/format'

import {Button} from 'components/Button'
import {GridContainer} from 'components/Grid/Container'
import {Paper} from 'components/Paper'

import {useSocketError} from 'hooks/useSocketError'

import {DrawerApp} from '../../../../../components/DrawerApp/index'
import useStyles from './style'

export const AppVendaFilial: React.FC = () => {
  const classes = useStyles()
  const socket = getSocket()
  const TOKEN_APP_ID = '@TOKEN_APP_ID'
  const ID_APP = '@ID_APP'
  const id = localStorage.getItem(ID_APP)
  const token = localStorage.getItem(TOKEN_APP_ID)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])

  const [socketConnected, setSocketConnected] = useState(false)
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split('T')[0]
  )

  useSocketError({
    socket,
    errorHandler: () => {
      setLoading(false)
    }
  })

  useEffect(() => {
    const fetchTokenAndReconnectSocket = async () => {
      try {
        if (socket) {
          try {
            disconnectSocket(token)
            console.log('Disconnected')
          } catch (error) {
            console.error('Error disconnecting socket:', error)
          }
        }

        try {
          connectSocket()
          if (token) {
            joinRoom(token)
          }
          setSocketConnected(true)
        } catch (error) {
          console.error('Error connecting socket:', error)
        }
      } catch (error) {
        console.error('Error fetching token:', error)
      }
    }

    fetchTokenAndReconnectSocket()

    // Função de limpeza para desconectar o socket ao desmontar o componente
    return () => {
      if (socketConnected) {
        disconnectSocket(token)
        console.log('Socket disconnected')
      }
    }
  }, [socket, token, socketConnected])

  const fetchProducts = useCallback(async () => {
    setLoading(true)
    socket.emit('filtroVendaFilialApp', {
      message: {
        room: token,
        filtro: selectedDate
      }
    })
    socket.on('vendaFilialDadosApp', (receivedData: any) => {
      try {
        const dados = receivedData?.dados

        if (Array.isArray(dados)) {
          setData(dados)
        } else {
          console.error('Dados recebidos não são um array.')
        }
      } catch (error) {
        console.error('Erro ao processar dados recebidos:', error)
      } finally {
        setLoading(false)
      }
    })
  }, [selectedDate, socket, token])

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value)
  }

  const generateReport = useCallback(async () => {
    if (socketConnected) {
      await fetchProducts()
    }
  }, [fetchProducts, socketConnected])

  if (!id || !token) {
    return <Redirect to="/app/login" />
  }

  return (
    <GridContainer spacing={0} className={classes.container}>
      <Toaster />
      <DrawerApp />
      <Paper className={classes.paper}>
        <TextField
          id="date"
          label="Selecione a data"
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          className={classes.textField}
          InputLabelProps={{
            shrink: true
          }}
        />
        <Button onClick={generateReport}>Gerar</Button>

        {loading ? (
          <div className={classes.root}>
            <CircularProgress />
          </div>
        ) : !data || data.length <= 1 ? (
          <div className={classes.root}>
            <p>Nenhum dado encontrado!</p>
          </div>
        ) : (
          data.map((result: any, index: number) => (
            <Card key={index} className={classes.card}>
              <CardContent>
                <h3 className={classes.p}>
                  {result?.nomefilial || 'Sem informação.'}
                </h3>
                <p className={classes.p}>
                  Valor Total:{' '}
                  {formatPrice(result?.totalvendas) || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Quantidade de Vendas:{' '}
                  {result?.quantidade || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Ticket Médio:{' '}
                  {formatPrice(result?.ticketmedio) || 'Sem informação.'}
                </p>
                <p className={classes.p}>
                  Margem de Lucro:{' '}
                  {Number(result?.margemlucro).toFixed(2) + ' %' ||
                    'Sem informação.'}
                </p>
                {result?.totalvendas !== null &&
                  result?.margemlucro !== null && (
                    <p className={classes.p}>
                      Lucro:{' '}
                      {formatPrice(
                        Number(result?.totalvendas) *
                          (Number(result?.margemlucro) / 100)
                      )}
                    </p>
                  )}
              </CardContent>
            </Card>
          ))
        )}
      </Paper>
    </GridContainer>
  )
}
